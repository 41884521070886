import ReactQuill, { Quill } from 'react-quill';
import React,{ useEffect, useState } from 'react';

const modulesQuill = {
  toolbar: {
    container: [
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'font': [] }],
      [{ 'align': [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'color': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466', 'custom-color'] }],
    ],
    handlers: {
      'color': function (value) {
        if (value === 'custom-color') {
          const color = window.prompt('Enter Hex Color Code');
          if (color) {
            this.quill.format('color', color);
          }
        } else {
          this.quill.format('color', value);
        }
      }
    }
  },
  clipboard: {
    matchVisual: false,
  },
};

const QuillEditor = ({ information, handleChange }) => {
  const [quillInstance, setQuillInstance] = useState(null);

  useEffect(() => {
    const reactQuillRef = document.querySelector('.ql-container');
    if (reactQuillRef) {
      const quill = new Quill(reactQuillRef, {
        modules: modulesQuill,
        theme: 'snow',
      });
      setQuillInstance(quill);

      quill.on('text-change', () => {
        handleChange('description', quill.root.innerHTML);
      });
    }

    return () => {
      if (quillInstance) {
        quillInstance.destroy();
      }
    };
  }, []); //eslint-disable-line

  return (
    <ReactQuill
      formats={[
        'header',
        'font',
        'size',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
        'video',
        'color'
      ]}
      theme="snow"
      value={information?.description ?? ''}
      onChange={handleChange}
    />
  );
};

export  default QuillEditor;
