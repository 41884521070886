import { format } from 'date-fns';

export const formatDate = (string) => {
  return format(
    new Date(
      string.substring(0, 4),
      string.substring(5, 7),
      string.substring(8, 10)
    ),
    'yyyyMMdd'
  );
};

export const transformToDate = (string) => {
  return new Date(
    string.substring(0, 4),
    string.substring(5, 7),
    string.substring(8, 10)
  );
};

export const filterAccess = ({ userData, siteId, param, action }) => {
  if (userData.role === 'superadmin') {
    return true;
  }
  const acc = userData.roleAccess
    ? userData.roleAccess.access.find(
        (userAccess) => userAccess.siteId === siteId
      )
    : userData.access.find((userAccess) => userAccess.siteId === siteId) ??
      userData.access[0];
  if (acc) {
    if (acc[param]) return acc[param][action];
    return false;
  }
  return false;
};





// Convert RGB to Hex
function rgbToHex(r, g, b) {
  return `#${[r, g, b]
    .map((x) => x.toString(16).padStart(2, "0"))
    .join("")}`;
}

// Convert Hex to RGB
function hexToRgb(hex) {
  const bigint = parseInt(hex.slice(1), 16);
  return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
}

// Adjust brightness of a hex color
function adjustBrightness(hex, amount) {
  const [r, g, b] = hexToRgb(hex).map((val) =>
    Math.min(255, Math.max(0, val + Math.floor(amount * 255)))
  );
  return rgbToHex(r, g, b);
}

export function generateFrenchShades(count) {
  const baseColors = [
    "#A8DADC", // Pastel Mint
    "#457B9D", // Steel Blue
    "#E63946", // Rose Red
    "#F4A261", // Warm Beige
    "#2A9D8F", // Sage Green
    "#FFE8D6", // Cream Peach
    "#6D6875", // Dusty Purple
    "#F6BD60", // French Yellow
    "#E5989B", // Dusty Pink
    "#B5838D", // Muted Mauve
    "#5C374C", // Wine
    "#BC4749", // Brick Red
    "#90BE6D", // Moss Green
  ];

  const shades = [];

  for (let i = 0; i < count; i++) {
    const baseColor = baseColors[i % baseColors.length]; // Cycle through base colors
    const shade = adjustBrightness(baseColor, Math.random() * 0.4 - 0.2); // Slightly lighten/darken
    shades.push(shade);
  }

  return shades;
}



